export default {
    getCheckStatus(e) {
        if (e === 'UNHANDLED') {
            return '未处理';
        } else if (e === 'PASS') {
            return '通过';
        } else if (e === 'REJECT') {
            return '驳回';
        }
    },
    getDemandState(e) {
        if (e === 'NOT_START') {
            return '未开始';
        } else if (e === 'UNDER_WAY') {
            return '进行中';
        } else if (e === 'EXPIRED') {
            return ' 已过期';
        }
    }
};
