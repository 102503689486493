let Home = () => import('../views/Home.vue');

const basicRoutes = [
    {
        path: '/',
        name: 'blank',
        title: '系统',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        title: '登录',
        component: () => import('../views/login/Login.vue')
    },
    {
        path: '/home',
        name: 'home',
        title: '首页',
        meta: {
            title: '首页'
        },
        redirect: '/index',
        component: Home,
        children: [
            {
                path: '/index',
                name: 'index',
                title: '首页',
                redirect: '/industry',
                meta: {
                    title: '首页，展示在标签上'
                },
                component: () => import('../views/index/Index.vue')
            },
            {
                path: '/industry',
                name: 'industry',
                title: '产业生态',
                meta: {
                    title: '产业生态'
                },
                component: () => import('../views/InfoPub/IndustryEco')
            },
            {
                path: '/product',
                name: 'product',
                title: '产品介绍',
                meta: {
                    title: '产品介绍'
                },
                component: () => import('../views/InfoPub/ProductIntro')
            },
            {
                path: '/article',
                name: 'article',
                title: '知识中心',
                meta: {
                    title: '知识中心'
                },
                component: () => import('../views/InfoPub/ArticleCenter')
            },
            {
                path: '/notice',
                name: 'notice',
                title: '公告中心',
                meta: {
                    title: '公告中心'
                },
                component: () => import('../views/InfoPub/NoticeCenter')
            },
            {
                path: '/requirement',
                name: 'requirement',
                title: '需求审核',
                meta: {
                    title: '需求审核'
                },
                component: () => import('../views/AuditManage/Requirement')
            },
            {
                path: '/scheme',
                name: 'scheme',
                title: '方案审核',
                meta: {
                    title: '方案审核'
                },
                component: () => import('../views/AuditManage/Scheme')
            },
            {
                path: '/accounts',
                name: 'accounts',
                title: '账号审核',
                meta: {
                    title: '账号审核'
                },
                component: () => import('../views/AuditManage/Accounts')
            },
            {
                path: '/provider',
                name: 'provider',
                title: '服务提供商审核',
                meta: {
                    title: '服务提供商审核'
                },
                component: () => import('../views/AuditManage/Provider')
            },
            {
                path: '/settings',
                name: 'settings',
                title: '配置中心',
                meta: {
                    title: '配置中心'
                },
                component: () => import('../views/Settings')
            },
            {
                path: '/commissionRule',
                name: 'commissionRule',
                title: '佣金规则',
                meta: {
                    title: '佣金规则'
                },
                component: () => import('../views/transactionManagement/commissionRule')
            },
            {
                path: '/pushManagement',
                name: 'pushManagement',
                title: '推送人员管理',
                meta: {
                    title: '推送人员管理'
                },
                component: () => import('../views/transactionManagement/pushManagement')
            },
            {
                path: '/transactionRecord',
                name: 'transactionRecord',
                title: '交易记录',
                meta: {
                    title: '交易记录'
                },
                component: () => import('../views/transactionManagement/transactionRecord')
            }
        ]
    }
];

export default basicRoutes;
