const env = 'production'; //测试环境-test、开发环境-dev、生产环境-production
let config = {};
if (env === 'dev') {
	// 开发环境
	config = {
		api: 'http://192.168.4.60:26892',
		imgUrl: 'ws://192.168.4.60:26892/websocket'
	};
} else if (env === 'test') {
	// 测试环境
	config = {
		api: 'http://182.151.11.69:26892',
		imgUrl: 'http://182.151.11.69:26892/attachment'
	};
} else if (env === 'production') {
	// 生产环境
	config = {
		api: 'https://op.csin.cloud',
		imgUrl: 'https://op.csin.cloud/attachment'
	};
}
export default {
    apiTimeout: 30000,
    tokenAge: 10 * 24 * 60 * 60 * 1000, //token失效时间
    url: '',
    gaodeMapKey: '2faaca36dc73b043479bb59b21e697fc', //公司,
    api: config.api,
	imgUrl: config.imgUrl,
    mqttApi: 'wss://mqtt.vitalong.cn:10443',
    debug: true
};
