import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import routes from './router/index.js';
import _config from './utils/config';
import { _http } from './utils/http';
import _comm from './utils/common';
import storage from '@/utils/storage';
import _selectOpt from './utils/selectOpt';
import _ECtrans from './utils/ECtrans';
import RouterTab from 'vue-router-tab';
import 'vue-router-tab/dist/lib/vue-router-tab.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/base.scss';
import QuillEditor from '@/components/quilleditor.vue';
import Wangeditor from '@/components/Wangeditor.vue';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import customPagination from './components/customPagination';

const router = new VueRouter({
    mode: 'hash',
    routes: routes
});

Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(RouterTab);
Vue.use(customPagination);

Vue.component('quill-editor', QuillEditor);
Vue.component('Wang-editor', Wangeditor);

//重写message只显示最新一个
import resetMessage from './utils/message';
Vue.prototype.$message = resetMessage;

Vue.prototype._http = _http; //api调用方式
Vue.prototype._comm = _comm; //公共方法使用
Vue.prototype._storage = storage;
Vue.prototype._config = _config;
Vue.config.productionTip = false;
Vue.prototype._selectOpt = _selectOpt;
Vue.prototype._ECtrans = _ECtrans;

router.beforeEach((to, from, next) => {
    if (storage.LStorage.get('_t')) {
        next();
    } else {
        if (to.path == '/login') {
            next();
        } else {
            router.push('/login');
        }
    }
});

new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app');

