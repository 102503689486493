import axios from 'axios';
import qs from 'qs';
import config from './config';
import storage from '@/utils/storage';
import comm from '@/utils/common';
import { MessageBox, Loading } from 'element-ui';
import { Message, Notify } from './resetPopup'; //重置弹窗
axios.defaults.baseURL = config.api;
// axios.defaults.withCredentials = false;
const _axios = function (opt) {
    let options = opt.data;
    // 清除空数据和数字类型数据转换
    // options = comm.convertParams(options);
    // if (!opt.isFormData) {
    //     options = comm.convertParams(options);
    // }
    // console.log('5888', storage.LStorage.get('_t'));
    axios.defaults.headers.common['Authorization'] = storage.LStorage.get('token');
    // if (config.debug) {
    //     let _debugApi = comm.querystring('debugapi');
    //     _debugApi && (opt.url = _debugApi + opt.url);
    // }

    let tempParams;
    // 数据提交类型
    if (opt.isBody) {
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.put['Content-Type'] = 'application/json';
        axios.defaults.headers.delete['Content-Type'] = 'application/json';
        // tempParams = JSON.stringify(options);
        tempParams = options;
    } else {
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        tempParams = qs.stringify(options);
    }
    if (opt.type == 'post') {
        if (opt.responseType == 'blob') {
            return axios.post(opt.url, tempParams, { responseType: 'blob' });
        } else {
            return axios.post(opt.url, tempParams);
        }
    } else if (opt.type == 'get') {
        return axios.get(opt.url, { params: options });
    } else if (opt.type == 'put') {
        return axios.put(opt.url, tempParams);
    } else if (opt.type == 'delete') {
        return axios.delete(opt.url, { data: options });
    }
};
/*************参数组成*************/
/* opt = {
    url: '',
    type: '',//列如get
    data: {},//传入参数
    success: function() {},//接口调用成功,status为true
    success_false: function() {},//接口调用成功,status为false
    failure: function() {},//接口调用失败
    isLoading:false //禁止显示loading，为true关闭loading
}*/
export const _http = (opt) => {
    let $Loading;
    if (opt.isLoading) {
        $Loading = Loading.service({
            lock: true,
            text: opt.loadingTxt || '数据加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    _axios(opt)
        .then((res) => {
            let httpRes = res.data;
            let tempOptions = comm.convertParams(opt.data);
            // console.log('');
            // console.log('api:', opt.url);
            // if (opt.isBody) {
            //     console.log('api传入参数：', JSON.stringify(tempOptions));
            // } else {
            //     console.log('api传入参数：\n', qs.stringify(tempOptions).replace(/=/g, ':').replace(/&/g, '\n'));
            // }
            // console.log('api返回数据：', httpRes);
            // console.log('');
            // 接口调用成功,状态为true

            if (httpRes.code == 200) {
                // 验证码
                // opt.success && opt.success(httpRes);
            } else if (httpRes.success || httpRes.code == 'DEFAULT_SUCCESS') {
                // opt.success && opt.success(httpRes);
            } else if (opt.responseType == 'blob') {
                // opt.success && opt.success(res);
                // httpRes.code == 'FAIL' &&
            } else if (httpRes.msg == '请重新登录') {
                MessageBox.alert(httpRes.msg || '数据验证失败', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                        let userType = storage.LStorage.get('_type');
                        // if (userType == 1) {
                        //     comm.logout(() => {
                        //         location.href = '/#/loginBack';
                        //     });
                        // } else {
                        comm.logout(() => {
                            location.href = '/#/login';
                        });
                        // }
                    }
                });
                opt.success_false && opt.success_false(httpRes);
            } else if (httpRes.code == '403' || httpRes.code == 'NOT_LOGGED_IN') {
                // MessageBox.alert('登录超时', '系统提示', {
                //     confirmButtonText: '确定',
                //     callback: (action) => {
                //         // 登录超时，退回登录页
                //         storage.LStorage.set('routerviwe', 0);
                //         comm.logout(() => {
                //             location.href = '/';
                //         });
                //     }
                // });
                // opt.success_false && opt.success_false(httpRes);
                let userType = storage.LStorage.get('_type');
                if (userType == 1) {
                    comm.logout(() => {
                        location.href = '/#/loginBack';
                    });
                } else {
                    comm.logout(() => {
                        location.href = '/#/login';
                    });
                }
            } else if (httpRes.code == '502') {
                MessageBox.alert('请求错误', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {}
                });
                opt.success_false && opt.success_false(httpRes);
            } else if (httpRes.code == '400') {
                MessageBox.alert(httpRes.msg || '数据验证失败', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {}
                });
                // Message({
                //     message: httpRes.msg,
                //     type: 'error',
                //     duration: 2000
                // });
                opt.success_false && opt.success_false();
            } else if (httpRes.code == '444') {
                MessageBox.alert(httpRes.msg || '数据验证失败', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {}
                });
                // Message({
                //     message: httpRes.msg,
                //     type: 'error',
                //     duration: 2000
                // });
                opt.success_false && opt.success_false();
				// opt.success && opt.success(res);
            } else if (httpRes.code == '441') {
                // console.log('httpRes.message->' + httpRes.message);
                MessageBox.alert(httpRes.msg || '数据验证失败', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {}
                });
                opt.success_false && opt.success_false(httpRes);
            } else if (httpRes.code == '442') {
                MessageBox.alert(httpRes.msg || '网络异常', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {}
                });
                opt.success_false && opt.success_false(httpRes);
            } else if (httpRes.code == 'USER_NOT_LOGIN') {
                // MessageBox.alert('登录超时', '系统提示', {
                //     confirmButtonText: '确定',
                //     callback: (action) => {
                //         // 登录超时，退回登录页
                //         comm.logout(() => {
                //             location.href = '/';
                //         });
                //     }
                // });
                let userType = storage.LStorage.get('_type');
                if (userType == 1) {
                    comm.logout(() => {
                        location.href = '/#/loginBack';
                    });
                } else {
                    comm.logout(() => {
                        location.href = '/#/login';
                    });
                }
                // opt.success_false && opt.success_false(httpRes);
            } else if (httpRes.code == 'FREE_SIGN_OVER_USE') {
                opt.success_false && opt.success_false(httpRes);
            }
            // 接口调用错误提示
            else {
                if (httpRes.code != '200') {
                    MessageBox.alert(httpRes.info || '数据验证失败', '系统提示', {
                        confirmButtonText: '确定',
                        callback: (action) => {}
                    });
                    opt.success_false && opt.success_false(httpRes);
                }

                // if (window.location.hash !== '#/search') {
                //     if (httpRes.code == 'DEFAULT_FAILED' || httpRes.code == 'PARAMETER_EXCEPTION' || httpRes.code == 'UNKNOWN_EXCEPTION') {
                //         MessageBox.alert(httpRes.info, '系统提示', {
                //             confirmButtonText: '确定',
                //             callback: (action) => { }
                //         });
                //     }
                // }

                // opt.success_false && opt.success_false(httpRes);
            }
            $Loading && $Loading.close();
			opt.success && opt.success(res.data);
        })
        .catch((err) => {
            console.log('接口错误信息------------->', err);
            let httpResponse = err.response;
            if (httpResponse && httpResponse.data.code == 442) {
                MessageBox.alert(httpResponse.data.msg || '网络异常', '系统提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {}
                });
            } else {
                // MessageBox.alert(opt.url + '解析错误', '系统提示', {
                //     confirmButtonText: '确定',
                //     callback: (action) => { }
                // });
                console.error(opt.url + '参数解析异常');
            }
            $Loading && $Loading.close();
			opt.fail && opt.fail(err);
        });
};
