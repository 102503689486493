const checkStatus = [
    {
        value: 'UNHANDLED',
        label: '未处理'
    },
    {
        value: 'REJECT',
        label: '驳回'
    },
    {
        value: 'PASS',
        label: '通过'
    }
];
export default {
    checkStatus: checkStatus
};
