<template>
	<div style="border: 1px solid #ccc">
		<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
		<Editor style="height: 500px; overflow-y: hidden" v-model="content" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" @customPaste="customPaste" />
	</div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { Message } from 'element-ui';
export default {
	components: {
		Editor,
		Toolbar
	},
	props: {
		modelValue: null
	},
	data() {
		return {
			content: this.modelValue,
			editor: null,
			toolbarConfig: {},
			editorConfig: {
				placeholder: '请输入知识详情',
				MENU_CONF: {
					// 配置上传图片
					uploadImage: {
						//server必须要配置正确
						server: this._config.api + '/api/common/upload-file?module=knowledge',
						maxFileSize: 10 * 1024 * 1024, // 1M
						// 最多可上传几个文件，默认为 100
						maxNumberOfFiles: 10,
						// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
						allowedFileTypes: [],
						// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
						fieldName: 'file',
						meta: {
							//官网中把token放到了这里，但是请求的时候会看不到token
						},
						headers: {
							//所以token放这里
							Authorization: this._storage.LStorage.get('_t')
						},
						// 将 meta 拼接到 url 参数中，默认 false
						metaWithUrl: false,
						// 跨域是否传递 cookie ，默认为 false
						withCredentials: false,
						// 超时时间，默认为 10 秒
						timeout: 5 * 1000, // 5 秒
						onSuccess(file, res) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						onFailed(file, res) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						// 上传错误，或者触发 timeout 超时
						onError(file, err, res) {
							if (file.size / 1024 / 1024 > 10) {
								Message.warning('上传文件超过10M');
								return;
							}
							Message.error(res.msg || '上传失败');
						},
						customInsert(res, insertFn) {
							if (res.code == 200) {
								insertFn(res.data.absolutePath);
							}
						}
					},
					// 配置视频上传
					uploadVideo: {
						server: this._config.api + '/api/common/upload-file?module=knowledge&size=100', //上传接口地址
						headers: {
							Authorization: this._storage.LStorage.get('_t')
						},
						// maxNumberOfFiles: 1, // 最多可上传几个文件，默认为 5
						fieldName: 'file', //上传文件名
						maxFileSize: 100 * 1024 * 1024, // 100M 单个文件的最大体积限制，默认为 2M
						onSuccess(file, res) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						onFailed(file, res) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						// 上传错误，或者触发 timeout 超时
						onError(file, err, res) {
							if (file.size / 1024 / 1024 > 100) {
								Message.warning('上传文件超过100M');
								return;
							}
							Message.error(res.msg || '上传失败');
						},
						customInsert(res, insertFn) {
							if (res.code == 200) {
								insertFn(res.data.absolutePath);
							}
						}
					},
					// 附件上传
					uploadAttachment: {
						server: this._config.api + '/api/common/upload-file?module=knowledge', // 服务端地址
						timeout: 10 * 1000, // 5s
						fieldName: 'file',
						headers: {
							Authorization: this._storage.LStorage.get('_t')
						},
						maxFileSize: 10 * 1024 * 1024, // 10M
						onSuccess(file, res) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						onFailed(file, res) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						// 上传错误，或者触发 timeout 超时
						onError(file, err, res) {
							if (file.size / 1024 / 1024 > 10) {
								Message.warning('上传文件超过10M');
								return;
							}
							Message.error(res.msg || '上传失败');
						},
						customInsert(res, file, insertFn) {
							if (res.code == 200) {
								Message.success(`${file.name} 上传成功`);
								insertFn(`附件-${file.name}`, res.data.absolutePath);
							} else {
								Message.error(res.msg || '上传失败');
							}
						},
						// 插入到编辑器后的回调
						onInsertedAttachment(elem) {
							// console.log('inserted attachment', elem);
						}
					}
				}
			},
			mode: 'default' // or 'simple'
		};
	},
	methods: {
		onCreated(editor) {
			this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
			// 由于某些字体不能商用，官方是商用网站，所以这里直接隐藏所有字体选择
			this.toolbarConfig = {
				excludeKeys: ['fontFamily']
			};
		},
		customPaste(editor, event, callback) {
			// 去掉格式
			const text = event.clipboardData.getData('text/plain'); // 获取粘贴的纯文本
			editor.insertText(text);
			event.preventDefault();
			callback(false);
		}
	},
	beforeDestroy() {
		const editor = this.editor;
		if (editor == null) return;
		editor.destroy(); // 组件销毁时，及时销毁编辑器
	},
	watch: {
		modelValue: {
			handler(val) {
				this.content = val;
			},
			deep: true,
			immediate: true
		},
		content(val) {
			//设置监听，如果改变就更新 p_model
			this.$emit('update:modelValue', val);
		}
	}
};
</script>

<style></style>
